import React from 'react'
import ReactMarkdown from "react-markdown";


export default ({banner, title, children}) => (
  <div>
    <img src={banner.publicURL} alt={title} className="w-100 my-5"/>
    <h5 className="text-center font-weight-bold pb-3">{title}</h5>
    <ReactMarkdown source={children} className="text-muted"/>
  </div>
)
